@import 'assets/styles/variables.scss';

.inputWrapper {
  box-sizing: border-box;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid transparent;
  width: 100%;

  &.invalid {
    border-color: $error_color;
  }

  & input {
    font-family: $monserrat;
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 8px 16px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    border-radius: 8px;
    background: #f7f7f7;

    &:disabled {
      color: #bbb;
    }
  }

  &.error {
    border-color: $error_color;
  }
}

.listbox {
  width: 250px;
  box-sizing: border-box;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 3;

  & li {
    font-family: $monserrat;
    padding: 5px 12px;
    display: flex;
    align-items: center;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
}

.tag {
  font-family: $monserrat;
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #7aa5ba;
  color: #fff;
  border-radius: 8px;
  box-sizing: content-box;
  padding: 1px 2px 1px 8px;
  outline: 0;
  overflow: hidden;
  font-size: 0.8125rem;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    width: 12px;
    height: 12px;
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
}
